<template>
  <div id="donations-programs-view">
    <vs-row
      style="padding-bottom: 30px"
    >
      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="12"
        v-can="permissions.add"
      >
        <vs-button
          icon-pack="feather"
          icon="icon-plus"
          class="mr-4"
          @click="createProgram"
        >
          إضافة برنامج جديد
        </vs-button>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col
        vs-lg="3"
        vs-sm="8"
        vs-xs="12"
        v-for="prog in programs"
        :key="prog.id"
      >
        <program-card
          :title="prog.name"
          :img="prog.images[0]"
          :link="`/donations/programDetails/${prog.id}`"
          :id="prog.id"
        />
      </vs-col>
    </vs-row>
    <store-popup
      :program="program"
      title="إضافة برنامج"
      @created="programCreated"
      ref="storePopupRef"
    />
  </div>
</template>

<script>
import {RepositoryFactory} from "../../donations-repositories/donationsRepositoryFactory";
import programCard from "@/app/donations-website/components/ProgramCard.vue";
import StorePopup from './programPopup.vue';
import utilities from "@/app/shared/utilities";

const programsRepo = RepositoryFactory.get("programsRepository");
export default {
  name: "Programs",
  components: {
    programCard,
    StorePopup,
  },
  methods: {
    createProgram() {
      utilities.initObjectMembers(this.program);
      this.$refs.storePopupRef.open();
    },
    async fetchAllPrograms() {
      this.programs = await programsRepo.fetchAllPrograms();
      this.isLoading = false;
    },
    programCreated(program) {
      this.programs.push(program);
    },
  },
  async created() {
    await this.fetchAllPrograms();
  },
  data() {
    return {
      isLoading: true,
      programs: [],
      program: {
        collectedAmount: '',
        id: null,
        name: '',
        description: '',
        location: '',
        status: '',
        beneficiariesNumber: '',
        targetAmount: '',
        finishedAt: '',
        images: [],
        order: '',
        programHistoryIds: [],
        previousActivities: [],
        createdAt: '',
        updatedAt: '',
        campaignsCount: null,
        emergenciesCount: null
      },
      permissions: {
        add: "store_programs",
        update: "update_programs",
        delete: "destory_programs"
      }
    };
  },
};
</script>

<style scoped>
.home_card {
  background-color: white;
  border-radius: 15px;
}

.card_title {
  font-size: 16px;
  font-weight: 700;
  color: #5E445A;
}

.vx-card img.card-img-top {
  border-top-right-radius: 0rem !important;
  border-top-left-radius: 0rem !important;
}
</style>
