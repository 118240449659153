<template>
  <div class="vx-row">
    <div
      class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base"
      @click="RouteTo(link)"
    >
      <vx-card
        class="home_card"
        style="width:225px;height:240px;cursor: pointer;"
      >
        <div>
          <img
            :src="img"
            alt="content-img"
            class="responsive"
            style="width:185px;height:160px"
          >
        </div>
        <h5 class="card_title text-center mt-5">
          <b>{{ title }}</b>
        </h5>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgramCard",
  props: {
    title: {type: String, default: null},
    link: {type: String, default: null},
    img: {type: String, default: null},
    id: {type: Number, default: null}
  },
  methods: {
    RouteTo(path) {
      this.$router.push({path: path});
    }
  }
};
</script>

<style scoped>

</style>
