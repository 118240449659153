import { render, staticRenderFns } from "./programs.vue?vue&type=template&id=6c5ef2d2&scoped=true&"
import script from "./programs.vue?vue&type=script&lang=js&"
export * from "./programs.vue?vue&type=script&lang=js&"
import style0 from "./programs.vue?vue&type=style&index=0&id=6c5ef2d2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c5ef2d2",
  null
  
)

export default component.exports